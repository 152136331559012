import React from "react";
import Display from "./Display";
export default class Table extends React.Component {
    static defaultProps = {
        fields: [],
        data: [],
        getKey: (datum, index) => datum?.id ?? index,
        link: null,
        fetching: false,
    };
    constructor(props) {
        super(props);
        const showFilters = this.filtersShown(props.fields);
        const showSelect = props.updateSelected !== undefined;
        this.state = {
            sortBy: "",
            filters: {
                options: {},
                range: {},
                array: {},
            },
            rowsShown: 25,
            pageShown: 1,
            searchBy: null,
            selected: [],
            showFilters,
            showSelect,
        };
    }
    componentDidMount() {
        const initialSelected = {};
        for (const { field, filterable } of this.props.fields) {
            if (filterable && filterable.defaultVal) {
                initialSelected[field] = [filterable.defaultVal];
            }
        }
        if (Object.keys(initialSelected).length > 0) {
            this.setState((previousState) => ({
                filters: {
                    ...previousState.filters,
                    options: {
                        ...previousState.filters.options,
                        ...initialSelected,
                    },
                },
            }));
        }
    }
    componentDidUpdate(previousProps) {
        const { data } = this.props;
        if (previousProps.data !== data) {
            if (this.props.updateSelected) {
                const selected = data.filter((datum, index) => {
                    const tableRowKey = "" + this.props.getKey(datum, index);
                    return this.state.selected.includes(tableRowKey);
                });
                const keys = selected.map((datum, index) => "" + this.props.getKey(datum, index));
                this.setState({ selected: keys });
                this.props.updateSelected(selected);
            }
            this.setState({
                showFilters: this.filtersShown(this.props.fields),
            });
        }
    }
    filtersShown = (fields) => fields.some(({ filterable = false }) => filterable) &&
        this.props.data.length > 0;
    updateSelected = (selected) => {
        this.setState({ selected });
        const s = this.props.data.filter((datum, index) => {
            const tableRowKey = "" + this.props.getKey(datum, index);
            return selected.includes(tableRowKey);
        });
        this.props.updateSelected(s);
    };
    toggleSort = (sortBy, canSort) => {
        if (!canSort) {
            return;
        }
        if (sortBy === this.state.sortBy) {
            sortBy = "-".concat(sortBy);
        }
        else if (this.state.sortBy[0] === "-") {
            sortBy = "";
        }
        this.setState({
            sortBy,
        });
    };
    updateFilter = (selected = [], field) => {
        console.log({ selected, field });
        const { filters } = this.state;
        if (selected.length > 0) {
            filters.options[field] = selected;
        }
        else {
            delete filters.options[field];
        }
        this.setState({ filters });
    };
    updateArray = (selected = [], field) => {
        const { filters } = this.state;
        if (selected.length > 0) {
            filters.array[field] = selected;
        }
        else {
            delete filters.array[field];
        }
        this.setState({ filters });
    };
    updateRange = (values, field) => {
        const { filters } = this.state;
        filters.range[field] = values;
        this.setState({ filters });
    };
    changePage = (page) => {
        this.setState({ pageShown: page });
    };
    changeRows = (rows) => {
        this.setState({ rowsShown: rows });
    };
    search = (event) => {
        try {
            const searchBy = new RegExp(event.target.value, "i");
            this.setState({ searchBy });
        }
        catch { }
    };
    render() {
        let fields = this.props.fields;
        const aliasedData = this.props.data.map((datum, index) => {
            return this.props.fields.reduce((converted, { field, alias }) => {
                converted[field] =
                    alias === undefined ? datum[field] : alias(datum);
                return converted;
            }, {
                tableRowKey: this.props.getKey(datum, index),
            });
        });
        const searchedData = fields
            .reduce((d, { field, searchable = false }) => {
            if (!searchable) {
                return d;
            }
            return d.map((datum) => {
                const isSearchMatch = datum.isSearchMatch ||
                    (datum[field] !== undefined &&
                        this.state.searchBy.test(datum[field].toString()));
                return { ...datum, isSearchMatch };
            });
        }, aliasedData.map((datum) => ({
            ...datum,
            isSearchMatch: this.state.searchBy === null,
        })))
            .filter(({ isSearchMatch }) => isSearchMatch);
        const filterFieldsRanges = [...fields].reduce((filterFieldsRanges, item) => {
            const { field, header, filterable = {} } = item;
            if (filterable.range) {
                const numbers = searchedData.map(({ [field]: index }) => index);
                const min = Math.min(...numbers);
                const max = Math.max(...numbers);
                if (min < max) {
                    filterFieldsRanges.push({
                        field,
                        header,
                        range: {
                            min,
                            max,
                        },
                    });
                }
            }
            return filterFieldsRanges;
        }, []);
        const { filteredData, filterFields, } = [...fields]
            .sort((a, b) => {
            const filters = Object.keys({ ...this.state.filters.options });
            let aI = filters.indexOf(a.field), bI = filters.indexOf(b.field);
            if (aI === -1)
                aI = filters.length;
            if (bI === -1)
                bI = filters.length;
            return aI - bI;
        })
            .reduce((dataAndFilters, { filterable, field, header }) => {
            if (!filterable ||
                Object.keys(filterable).some((bool) => !bool)) {
                return dataAndFilters;
            }
            if (filterable.options || filterable.toggle) {
                const { filters: { options: { [field]: filterBy = [] }, }, } = this.state;
                const filterOptions = [
                    ...new Set([
                        ...dataAndFilters.filteredData.map((datum) => datum[field] === undefined
                            ? "unknown"
                            : datum[field]),
                        ...filterBy,
                    ]),
                ];
                if (filterOptions.length > 1) {
                    dataAndFilters.filterFields.unshift({
                        field,
                        header,
                        defaultVal: filterable.defaultVal,
                        options: filterOptions,
                        selected: filterBy,
                        toggle: filterable.toggle,
                    });
                }
                if (filterBy.length > 0) {
                    dataAndFilters.filteredData =
                        dataAndFilters.filteredData.filter((datum) => filterBy.includes(datum[field]) ||
                            (datum[field] === undefined &&
                                filterBy.includes("unknown")));
                }
            }
            if (filterable.array) {
                const { filters: { array: { [field]: filterBy = [] }, }, } = this.state;
                let setOptions = [];
                for (const datum of dataAndFilters.filteredData) {
                    if (datum[field] !== undefined) {
                        setOptions = [...setOptions, ...datum[field]];
                        continue;
                    }
                    setOptions.push("unknown");
                }
                const filterOptions = [
                    ...new Set([...setOptions, ...filterBy]),
                ];
                if (filterOptions.length > 1) {
                    dataAndFilters.filterFields.unshift({
                        field,
                        header,
                        array: filterOptions,
                        arraySelected: filterBy,
                    });
                }
                if (filterBy.length > 0) {
                    dataAndFilters.filteredData =
                        dataAndFilters.filteredData.filter((datum) => filterBy.some((filter) => (datum[field] || []).includes(filter)) ||
                            (datum[field] === undefined &&
                                filterBy.includes("unknown")));
                }
            }
            if (filterable.range) {
                const range = this.state.filters.range[field];
                if (range) {
                    dataAndFilters.filteredData =
                        dataAndFilters.filteredData.filter((datum) => datum[field] >= range.min &&
                            datum[field] <= range.max);
                }
            }
            return dataAndFilters;
        }, {
            filteredData: searchedData,
            filterFields: filterFieldsRanges,
        });
        const sortedData = filteredData.sort((a, b) => {
            let sortBy = this.state.sortBy ?? "";
            if (sortBy === "") {
                return 0;
            }
            if (sortBy[0] !== "-") {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                return 0;
            }
            sortBy = sortBy.slice(1);
            if (a[sortBy] < b[sortBy]) {
                return 1;
            }
            if (a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        });
        const page = Math.min(this.state.pageShown, Math.ceil(sortedData.length / this.state.rowsShown));
        const shownData = this.props.noNav
            ? sortedData
            : sortedData.slice((page - 1) * this.state.rowsShown, page * this.state.rowsShown);
        fields = fields.filter(({ hidden }) => !hidden);
        return (React.createElement(Display, { fields: fields, data: shownData, showFilters: this.state.showFilters, showSelect: this.state.showSelect, filterFields: filterFields, selected: this.state.selected, link: this.props.link, page: page, rowsShown: this.state.rowsShown, dataSize: sortedData.length, sortBy: this.state.sortBy, updateSelected: this.updateSelected, changePage: this.changePage.bind(this), changeRows: this.changeRows.bind(this), updateFilter: this.updateFilter, search: this.search, toggleSort: this.toggleSort, fetching: this.props.fetching, reload: this.props.reload, updateArray: this.updateArray, updateRange: this.updateRange, activeRangeFilters: this.state.filters.range, noNav: this.props.noNav, alternate: this.props.alternate, totalCost: this.props.totalCost, largeFilter: this.props.largeFilter, light: this.props.light, noMargin: this.props.noMargin, noPadding: this.props.noPadding, className: this.props.className, setErrors: this.props.setErrors, padding: this.props.padding }));
    }
}
