import React from "react";
import styles from "./index.module.scss";
import Button from "@Components/Button";
import { DragAndDropUploader } from "@Components/DragAndDropUploader";
import CSVIcon from "@Components/Icons/CSV";
import Exit from "@Components/Icons/Exit";
import Upload from "@Components/Icons/Upload";
export function Manual(props) {
    return (React.createElement("div", { className: `${styles.card}` },
        React.createElement("div", { className: `${styles.titleContainer}` },
            React.createElement("div", { className: styles.icon },
                React.createElement(Upload, null)),
            React.createElement("h4", { className: styles.title }, "Manually upload your Bill\u00A0Reports")),
        React.createElement("div", { className: styles.content },
            React.createElement("div", { className: styles.middle },
                React.createElement(DragAndDropUploader, { validate: (file) => {
                        const csvRegex = /.*\.csv$/;
                        return csvRegex.test(file.name);
                    }, submit: props.submit, content: ({ files, remove, submit, click }) => {
                        let fileDisplay;
                        let summaryDisplay;
                        let itemisedDisplay;
                        if (files.length > 0) {
                            fileDisplay = (React.createElement("div", { className: styles.files },
                                React.createElement("h3", null, "Your files"),
                                React.createElement("div", { className: styles.fileList }, files.map(({ id, name, }) => (React.createElement("div", { key: id, className: styles.file },
                                    React.createElement("div", { className: styles.fileDetails },
                                        React.createElement("div", { className: styles.CSVicon },
                                            React.createElement(CSVIcon, null)),
                                        React.createElement("p", null, name),
                                        React.createElement("span", { className: styles.exit, onClick: () => remove(id) },
                                            React.createElement(Exit, { error: true, size: "0.75rem" }))))))),
                                files.length >= 2 && (React.createElement("div", { className: styles.buttons },
                                    React.createElement(Button, { click: click }, "ADD FILES"),
                                    files.length >= 4 && (React.createElement(Button, { click: submit }, "UPLOAD"))))));
                        }
                        if (files.length === 0) {
                            summaryDisplay = (React.createElement("div", { className: styles.noFiles },
                                React.createElement("h4", null,
                                    React.createElement("b", null, "Summary Report")),
                                React.createElement("p", { className: styles.subtitle }, "We need your most recent full month\u2019s report"),
                                React.createElement(Button, { click: click }, "SELECT CSV FOR UPLOAD")));
                        }
                        if (files.length < 2) {
                            itemisedDisplay = (React.createElement("div", { className: styles.noFiles },
                                React.createElement("h4", null,
                                    React.createElement("b", null, "Itemised Reports")),
                                React.createElement("p", { className: styles.subtitle },
                                    "We need a min. of",
                                    " ",
                                    React.createElement("b", { className: styles.highlight }, "three"),
                                    " ",
                                    "months of reports"),
                                React.createElement(Button, { click: click }, "SELECT CSVS FOR UPLOAD")));
                        }
                        return (React.createElement("div", { className: styles.body },
                            fileDisplay,
                            summaryDisplay,
                            itemisedDisplay));
                    }, onUpload: (files) => {
                        if (files.length >= 4) {
                            props.submit(files);
                        }
                    } })),
            React.createElement("div", { className: styles.bottom }, props.bottomSlot))));
}
export default Manual;
