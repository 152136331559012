import React from "react";
import ButtonLink from "@Components/ButtonLink";
import NoPhone from "@Components/Icons/NoPhone";
import Phone from "@Components/Icons/Phone";
import Tick from "@Components/Icons/Tick";
import Progress from "@Components/ProgressBar";
import InProgress from "@Components/Status/InProgress";
import Lost from "@Components/Status/Lost";
import Submitted from "@Components/Status/Submitted";
import WaitingClient from "@Components/Status/WaitingClient";
import Won from "@Components/Status/Won";
import ExpiredPopup from "@Components/TenderBoxes/ExpiredPopup";
import TenderFilter from "@Components/TenderBoxes/TenderFilter";
import GetPath from "@Helpers/GetPath";
import { CustomerType } from "@Shared/Entities/Customer/Tender/enums";
import { Status } from "@Shared/Entities/Supplier/Response/enums";
import { platformToNetwork } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { displayDate } from "@Shared/Helpers/Date";
import { getIconFromNetwork } from "@Shared/Helpers/Network";
import Button from "@Components/Button";
import { PROPOSAL_EDIT, TENDER_RESPONSE } from "@Shared/Paths/Supplier";
import styles from "./index.module.scss";
export default class TenderBox extends React.Component {
    delete = (response) => {
        this.props.delete(response);
    };
    static props = {
        filterable: false,
    };
    getCreatedBy = (customerType) => {
        switch (customerType) {
            case CustomerType.Customer:
                return "C";
            case CustomerType.Broker:
                return "B";
            case CustomerType.Super_Broker:
                return "SB";
            case CustomerType.White_Label:
                return "WL";
        }
    };
    render() {
        if (this.props.responses.length === 0) {
            return React.createElement("div", null, "No responses found");
        }
        const dashboard = this.props.dashboard ? styles.dashboard : "";
        const responsesGroup = this.props.dashboard ? "" : styles.responsesGroup;
        const unsubmit = (response) => async () => {
            await this.props.unsubmitResponse(response);
        };
        return (React.createElement(React.Fragment, null,
            this.props.filterable === true && (React.createElement(TenderFilter, { tenders: this.props.tenders, responses: this.props.responses })),
            React.createElement("div", { className: `${styles.responses} ${responsesGroup}` }, this.props.responses.map((response) => {
                const tender = this.props.tenders.find(({ id }) => id === response.tenderId);
                return (React.createElement("div", { className: `${styles.tenderBoxes} ${dashboard}`, key: response.id },
                    response.status === Status.Draft &&
                        !tender.isOpen && (React.createElement(ExpiredPopup, { responses: this.props.responses, tenders: this.props.tenders, delete: this.delete })),
                    React.createElement("div", { className: styles.wrapper },
                        React.createElement("div", { className: styles.header },
                            React.createElement("div", null,
                                React.createElement("div", null,
                                    "Tender Id:",
                                    " ",
                                    tender.id.toUpperCase()),
                                React.createElement("div", null,
                                    "Response Id:",
                                    " ",
                                    response.id.toUpperCase())),
                            React.createElement("div", { className: styles.icon },
                                response.awarded === true &&
                                    !tender.isOpen && React.createElement(Won, null),
                                response.awarded === false && (React.createElement(Lost, null)),
                                response.status ===
                                    Status.Scored &&
                                    response.awarded ===
                                        undefined && (React.createElement(WaitingClient, null)),
                                response.status ===
                                    Status.Ready && React.createElement(Submitted, null),
                                response.status === Status.Draft &&
                                    tender.isOpen && React.createElement(InProgress, null)))),
                    React.createElement("div", { className: `${styles.wrapper} ${styles.infoWrapper}` },
                        React.createElement("div", { className: styles.box },
                            React.createElement("div", { className: styles.networkAndCreatedBy },
                                getIconFromNetwork(platformToNetwork(response.meta
                                    ?.primaryPlatform)),
                                React.createElement("div", { className: styles.createdBy }, this.getCreatedBy(tender.customerType)))),
                        React.createElement("div", { className: styles.box },
                            React.createElement("div", { className: styles.lines }, (tender.details.voiceLines || 0) +
                                (tender.details.dataLines ||
                                    0) +
                                (tender.details
                                    .voiceOnlyLines || 0)),
                            "Lines"),
                        tender.details.handsets === true && (React.createElement("div", { className: styles.box },
                            React.createElement("div", { className: styles.deviceWrapper },
                                React.createElement("div", { className: styles.deviceIcon },
                                    React.createElement(Phone, { size: "2em" })),
                                "Required"))),
                        tender.details.handsets !== true && (React.createElement("div", { className: styles.box },
                            React.createElement("div", { className: styles.deviceWrapper },
                                React.createElement("div", { className: styles.deviceIcon },
                                    React.createElement(NoPhone, { size: "2em" })),
                                "Not required")))),
                    React.createElement("div", { className: styles.wrapper },
                        React.createElement("div", { className: styles.content },
                            "Closing date",
                            tender.isOpen && (React.createElement("div", { className: styles.status }, displayDate(tender.supplierDeadline))),
                            !tender.isOpen && (React.createElement("div", { className: styles.status }, "Closed"))),
                        React.createElement("div", { className: styles.content },
                            "Progress",
                            response.status === Status.Draft && (React.createElement("div", { className: styles.progressBar },
                                React.createElement("div", { className: styles.bar },
                                    React.createElement(Progress, { index: 50, completeValue: 100 })),
                                React.createElement("div", { className: styles.label }, "50%"))),
                            response.status !== Status.Draft && (React.createElement("div", { className: styles.complete },
                                React.createElement(Tick, { size: "1vw" }),
                                "100%")))),
                    React.createElement("div", { className: styles.wrapper },
                        React.createElement("div", { className: styles.footer },
                            React.createElement(ButtonLink, { to: GetPath(TENDER_RESPONSE, response.id), dark: true }, "VIEW DETAILS"),
                            response.status === Status.Draft && (React.createElement(ButtonLink, { to: GetPath(PROPOSAL_EDIT, response.id) }, "CONTINUE")),
                            response.status === Status.Ready && (React.createElement(ButtonLink, { to: GetPath(PROPOSAL_EDIT, response.id) }, "EDIT")),
                            response.submittedAt !== undefined &&
                                tender.isOpen && (React.createElement(Button, { red: true, click: unsubmit(response) }, "UNSUBMIT"))))));
            }))));
    }
}
